import React from 'react';
import './Home.scss';

function Home() {
  return (
    <div className="container home" />
  );
}

export default Home;
