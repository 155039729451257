/* eslint react/prop-types: 0 */
import React, { useEffect, useRef } from 'react';
import { useLocation, Switch, Route } from 'wouter';
import Home from './Home';
import Works from './Works';
import About from './About';
import Contact from './Contact';
import ErrorPage from './ErrorPage';
import Cube from '../components/Cube';
import AnimatedRoute from '../components/AnimatedRoute';
import DraggableComp from '../components/Dragger';
import './App.scss';

export const compList = [
  {
    id: 0,
    name: 'home',
    comp: <Home />,
    path: '/',
  },
  {
    id: 1,
    name: 'works',
    comp: <Works />,
    path: '/works',
  },
  {
    id: 2,
    name: 'about',
    comp: <About />,
    path: '/about',
  },
  {
    id: 3,
    name: 'contact',
    comp: <Contact />,
    path: '/contact',
  },
];

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function Dragger({ cmList, currentPage, prevPage }) {
  const [, setLocation] = useLocation();

  let comparePage;

  if (currentPage === undefined) {
    setLocation('/error/404');
  } else if (prevPage === undefined) {
    comparePage = true;
  } else {
    comparePage = currentPage.id >= prevPage.id;
  }

  const getIndex = (val, type, min, max) => {
    let valoc = val;
    if (val !== min && type === 'prev') valoc -= 1;
    else if (val !== max && type === 'next') valoc += 1;
    return valoc;
  };

  return (
    <div className="dragger">
      {cmList.map((page) => (
        <AnimatedRoute
          key={page.id}
          path={page.path}
          type={comparePage ? 'pr' : 'pl'}
        >
          <DraggableComp
            prev={cmList[getIndex(page.id, 'prev', 0, 3)].path}
            current={cmList[page.id].path}
            next={cmList[getIndex(page.id, 'next', 0, 3)].path}
          >
            {page.comp}
          </DraggableComp>
          <div className="swipe-help"></div>
        </AnimatedRoute>
      ))}
    </div>
  );
}

function App() {
  const [location] = useLocation();
  const prevLocation = usePrevious(location);

  const pgs = [];
  for (let i = 0; i < compList.length; i += 1) {
    pgs[i] = compList[i].name;
  }

  const currentPage = compList.find((page) => page.path === location);
  const prevPage = compList.find((page) => page.path === prevLocation);

  return (
    <div className="cozalac">
      <div className="top-section">
        <Cube cubeFaces={pgs} show={currentPage !== undefined ? currentPage.name : ''} />
      </div>
      <div className="page-section">
        <Switch>
          <Route path="/error/:code" component={ErrorPage} />
          <Route path="/:page*">
            <Dragger cmList={compList} currentPage={currentPage} prevPage={prevPage} />
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
