import React from 'react';
import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URI_GRAPHQL
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
});

function Api(props) {
  return(
    <ApolloProvider client={client}>
      {props.children}
    </ApolloProvider>
  );
}

export default Api;
