/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import ErrorPage from './ErrorPage';
import './Works.scss';

export const GET_WORKS = gql`
  {
    works {
      id
      Content {
        Title
        BodyText
        Image {
          url
        }
      }
    }
  }
`;

export function WorkItem({ data }) {
  return (
    <div className="work-item container">
      <h1 className="work-title col-1-1 col-tb-1-2 row-1-1 row-tb-1-1">{data.Content.Title}</h1>
      <img data-testid="work-image" src={`${process.env.REACT_APP_API_URI}${data.Content.Image.url}`} alt="Work item" className="work-image col-2-7 col-tb-1-12 row-1-1 row-tb-1-1" />
      <div className="vertical col-8-12 col-tb-1-12 row-1-1 row-tb-2-2">
        <p className="work-content">{data.Content.BodyText}</p>
        <button className="work-go-button">view on project</button>
      </div>
    </div>
  );
}

export function ControlButtons({ workDec, workInc }) {
  return (
    <div className="control-buttons">
      <button className="prev" onClick={workDec}>prev</button>
      <button className="next" onClick={workInc}>next</button>
    </div>
  );
}

function Works() {
  const { loading, error, data } = useQuery(GET_WORKS);
  const [work, setWork] = useState(0);

  if (loading) return '';
  if (error) return <ErrorPage params={{ code: '500', message: error.message }} />;

  const handleWorkInc = () => setWork((id) => id < data.works.length - 1 ? id + 1 : id);
  const handleWorkDec = () => setWork((id) => id > 0 ? id - 1 : id);

  return (
    <div className="container works">
      <div className="work-section col-1-11 col-tb-1-12 row-1-1 row-tb-1-1"><WorkItem data={data.works[work]} /></div>
      <div className="control-section col-12-12 col-tb-6-7 row-1-1 row-tb-1-1"><ControlButtons workInc={handleWorkInc} workDec={handleWorkDec} /></div>
    </div>
  );
}

export default Works;
