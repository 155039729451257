/* eslint react/prop-types: 0 */
/* eslint dot-notation: 0 */
import React from 'react';
import './PopupMessage.scss';

function PopupMessage({ message, onClick }) {
  return (
    <div className={`modal ${(message === 'Your message has been sent' ? 'success' : 'error')} ${(message ? 'open' : '')}`}>
      <span className="close" role="button" aria-label="Close" onClick={onClick} />
      <span className="modal-text" role="alert">{message}</span>
    </div>
  );
}

export default PopupMessage;
