/* eslint react/prop-types: 0 */
/* eslint import/no-dynamic-require: 0 */
/* eslint global-require: 0 */
import React from 'react';
import './ErrorPage.scss';

function ErrorPage({ params }) {
  let { code, message } = params;

  const errorTexts = {
    403: 'Forbidden',
    404: 'Not Found',
    500: message,
  };

  if (errorTexts[code] === undefined) code = '404';

  return (
    <div className="error-page" data-testid="error-page">
      <div className="error-text">{`${errorTexts[code]}`}</div>
      <div className="error-code"><img className="code-img" src={require(`../global/img/error/${code}.svg`)} alt="Error code" /></div>
    </div>
  );
}

export default ErrorPage;
