/* eslint react/prop-types: 0 */
import React from 'react';
import './Cube.scss';

function CubeFace({ face, faceContent }) {
  return (
    <div data-testid="cube-face" className={`cube-face cube-face--${face}`}><h2 className="page-name">{faceContent}</h2></div>
  );
}

function Cube({ cubeFaces, show }) {
  const esle = {
    [cubeFaces[1]]: 'front',
    [cubeFaces[3]]: 'back',
    [cubeFaces[2]]: 'right',
  };

  return (
    <div className="scene">
      <div className={`cube show--${esle[show]}`}>
        <CubeFace face={esle[cubeFaces[2]]} faceContent={cubeFaces[2]} />
        <CubeFace face={esle[cubeFaces[1]]} faceContent={cubeFaces[1]} />
        <CubeFace face={esle[cubeFaces[3]]} faceContent={cubeFaces[3]} />
      </div>
    </div>
  );
}

export default Cube;
