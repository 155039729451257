import React from 'react';
import { useQuery, gql } from '@apollo/client';
import ErrorPage from './ErrorPage';
import './About.scss';

export const GET_ABOUT = gql`
  query getAbout($name: String!) {
		pages(where: { Name: $name }) {
			Name
			Content {
				Title
				BodyText
				Image {
					url
				}
			}
		}
	}
`;

function About() {
  const { loading, error, data } = useQuery(GET_ABOUT, {
    variables: { name: 'about' },
  });

  if (loading) return '';
  if (error) return <ErrorPage params={{ code: '500', message: error.message }} />;

  return (
    <div className="container about">
      <div className="about-section col-1-12 col-tb-1-12 row-1-1 row-tb-1-1 container">
        <p className="about-content col-1-5 col-tb-1-12 row-1-1 row-tb-2-2">{data.pages[0].Content.BodyText}</p>
        <img data-testid="about-image" src={`${process.env.REACT_APP_API_URI}${data.pages[0].Content.Image.url}`} alt="About us" className="about-image col-6-11 col-tb-1-12 row-1-1 row-tb-1-1" />
        <h1 className="about-title col-12-12 col-tb-1-2 row-1-1 row-tb-1-1">{data.pages[0].Content.Title}</h1>
      </div>
    </div>
  );
}

export default About;
