/* eslint react/prop-types: 0 */
/* eslint dot-notation: 0 */
import React from 'react';
import { useQuery, gql } from '@apollo/client';
import ContactForm from '../components/ContactForm';
import ErrorPage from './ErrorPage';
import './Contact.scss';

export const GET_CONTACT = gql`
  {
    pages(where: { Name: "contact" }) {
      Name
      Content {
        BodyText
      }
    }
  }
`;

function Contact() {
  const { loading, error, data } = useQuery(GET_CONTACT);

  if (loading) return '';
  if (error) return <ErrorPage params={{ code: '500', message: error.message }} />;

  return (
    <div className="container contact">
      <div className="bg-plane" />
      <div className="contact-section col-6-12 col-tb-1-12 row-1-1 row-tb-1-1">
        <p className="contact-content">{data.pages[0].Content.BodyText}</p>
        <div className="form-wrapper"><ContactForm /></div>
      </div>
    </div>
  );
}

export default Contact;
