/* eslint react/prop-types: 0 */
import React from 'react';
import { useRoute } from 'wouter';
import { CSSTransition } from 'react-transition-group';
import './AnimatedRoute.scss';

function AnimatedRoute({ path, type, children }) {
  const [match] = useRoute(path);

  return (
    <CSSTransition
      in={match}
      timeout={500}
      classNames={type}
      appear
      mountOnEnter
      unmountOnExit
    >
      <div className={type} data-testid="animated-route">
        {children}
      </div>
    </CSSTransition>
  );
}

export default AnimatedRoute;
