/* eslint react/prop-types: 0 */
import React, { useState } from 'react';
import { useLocation } from 'wouter';
import './Dragger.scss';

function DraggableComp(props) {
  const {
    prev,
    next,
    current,
    children,
  } = props;

  const [move, setMove] = useState({
    status: 'STOP',
    sposx: 0,
    posx: 0,
    percent: 0,
  });

  const [, setLocation] = useLocation();

  const startMove = (e) => {
    setMove({
      ...move,
      status: 'START',
      sposx: e.clientX ? e.clientX : e.touches ? e.touches[0].clientX : null,
    });
  };

  const stopMove = () => {
    if (move.percent > 20 && prev) {
      setLocation(prev);
      setMove({
        ...move,
        status: 'STOP',
      });
    } else if (move.percent < -20 && next) {
      setLocation(next);
      setMove({
        ...move,
        status: 'STOP',
      });
    } else {
      setMove({
        ...move,
        status: 'STOP',
        percent: 0,
      });
    }
  };

  const listenMove = (e) => {
    const clx = e.clientX ? e.clientX : e.touches ? e.touches[0].clientX : null;
    if (prev !== current && clx > move.sposx) {
      setMove({
        ...move,
        percent: Math.round(((clx - move.sposx) * 100) / window.innerWidth),
      });
    } else if (next !== current && clx < move.sposx) {
      setMove({
        ...move,
        percent: Math.round(((clx - move.sposx) * 100) / window.innerWidth),
      });
    }
    if (move.percent < -20 || move.percent > 20) stopMove();
  };

  return (
    <div
      className="draggable-comp"
      role="slider"
      aria-valuemin="-100"
      aria-valuemax="100"
      aria-valuenow={move.percent}
      onMouseDown={startMove}
      onMouseUp={stopMove}
      onMouseMove={move.status === 'START' ? listenMove : null}
      onMouseLeave={stopMove}
      onTouchStart={startMove}
      onTouchCancel={stopMove}
      onTouchEnd={stopMove}
      onTouchMove={move.status === 'START' ? listenMove : null}
    >
      {children}
    </div>
  );
}

export default DraggableComp;
