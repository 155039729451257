/* eslint react/prop-types: 0 */
/* eslint dot-notation: 0 */
import React, { useState } from 'react';
import PopupMessage from './PopupMessage';
import './ContactForm.scss';

function ContactForm() {
  const [mes, setMes] = useState('');

  const handleMessage = (message, de) => {
    // eslint-disable-next-line no-console
    console.info(message, de);
    setMes(message);
  };

  const handleClick = () => {
    setMes('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const formEl = event.target.elements;
    const postData = new FormData();

    postData.append('to', process.env.REACT_APP_EMAIL_ADDR_TO);
    postData.append('from', process.env.REACT_APP_EMAIL_ADDR);
    postData.append('replyTo', formEl['replyTo'].value);
    postData.append('subject', (`[Contact form - Cozalac] ${formEl['subject'].value}`));
    postData.append('html', (`
      Send from contact form of Cozalac <br/>
      <br/> E-mail: ${formEl['replyTo'].value}
      <br/> Name: ${formEl['name'].value}
      <br/> Message: ${formEl['text'].value}
    `));

    fetch(process.env.REACT_APP_EMAIL_API_URI, {
      method: 'POST',
      body: postData,
    })
      .then((response) => response.json())
      .then((data) => {
        handleMessage(data.message ? data.message : 'Your message has been sent', data);
      })
      .catch((error) => {
        handleMessage('Your message could not be sent', error);
      });
  };

  return (
    <React.Fragment key="0">
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="horizantal">
          <div className="form-group">
            <label htmlFor="text0">
              Name:
              <input id="text0" type="text" name="name" required />
            </label>
          </div>
          <div className="form-group">
            <label htmlFor="text1">
              Mail:
              <input id="text1" type="email" name="replyTo" required />
            </label>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="text2">
            Subject:
            <input id="text2" type="text" name="subject" required />
          </label>
        </div>
        <div className="form-group">
          <label htmlFor="textarea0">
            Message:
            <textarea id="textarea0" name="text" rows="3" required />
          </label>
        </div>
        <div className="form-group">
          <input type="submit" value="send" />
        </div>
      </form>
      <PopupMessage message={mes} onClick={handleClick} />
    </React.Fragment>
  );
}

export default ContactForm;
